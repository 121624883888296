<template>
  <!--  病例管理-->
  <div style="margin: -15px -15px">
  <a-spin :spinning="spinning">
  <div class="CaseManagement">
    <!--  病例搜索-->
    <div class="CaseSearch">
      <div>
<!--      <div class="searchC">-->
<!--        病例模板 &nbsp; <a-input v-model="searchValueC.CaseName" placeholder="输入病例模板" style="width: 210px" size="default" @pressEnter="enterSearch"/>-->
<!--      </div>-->
      <div class="searchC">
        医生 &nbsp; <a-input v-model="searchValueC.name" placeholder="输入医生姓名" size="default" style="width: 150px" @pressEnter="enterSearch"/>
      </div>
      <div class="searchC">
        状态  &nbsp;
          <a-select style="width: 150px"  placeholder="选择状态" v-model="searchValueC.mode" @change="settlementChange"  >
            <a-select-option value="PASSED">
              已通过
            </a-select-option>
            <a-select-option value="DENIED">
              已驳回
            </a-select-option>
            <a-select-option value="PENDING">
              待审批
            </a-select-option>
          </a-select>
      </div>
      <div class="searchC">上传时间 &nbsp;
        <a-range-picker @change="dateChange"  style="width: 220px" />
        <div class="searchButton">
          <a-button @click="searchOk" >查询</a-button>
          <a-button style="margin-left: 10px" @click="searchCancel" >重置</a-button>
        </div>
      </div>
      </div>
    </div>
    <div class="CaseForm">
      <div style="display: flex;justify-content: flex-end;margin: 10px;">
        <a-button @click="excelOk" style="margin-left: 5px"  ><a-icon type="file-excel" style="color: #1cc55f" />全部导出</a-button>
        <a-button @click="excelDate" style="margin-left: 5px"  ><a-icon type="file-excel" style="color: #1cc55f" />批量导出</a-button>
      </div>
      <a-table :columns="columns" :data-source="CaseList" :rowKey="record => record.id"   :pagination="false"
      >
        <span slot="Doctor" slot-scope="text,record">
          {{record.memberName}} - {{record.memberHospitalName}}
        </span>
        <span slot="Patient" slot-scope="text,record">
          <span v-html="record.patientSex === 1 ? '男':'女'">{{record.patientSex}}</span>-
          {{record.patientAge}}岁
        </span>
        <span slot="audit_status" slot-scope="text,record">
          <span v-if="record.audit_status === 'PENDING'">
            <div >待审批</div>
          </span>
          <span v-if="record.audit_status === 'PASSED'">
            <span >已通过</span>
          </span>
          <span v-if="record.audit_status === 'DENIED'">
            <span >已驳回</span>
          </span>
        </span>
        <span slot="isPrint" slot-scope="text,record">
          <span v-html="record.isPrint === 1 ? '已打印':'待打印'">{{record.isPrint}}</span>
        </span>
        <span slot="isAudit" slot-scope="text,record">
          <span v-html="record.isAudit === 1 ? '已结算':'未结算'">{{record.isPrint}}</span>
        </span>
        <div slot="operate" slot-scope="text,record">
<!--          <span @click="download(record.id)" style="color: #4a9bfa;cursor: pointer;">-->
<!--              下载-->
<!--            </span>-->
          <span v-if="casesh === '1' " style="color: #4a9bfa;cursor: pointer;" @click="checkOk(record)">查看</span>
        </div>
      </a-table>
      <div style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;margin-bottom: 10px">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="CasePagination" />
      </div>
    </div>
    <div style="display: flex;justify-content: flex-end;right: 45px;position: fixed;bottom: 3.5rem;z-index: 9999">
      <a-button type="primary" @click="back" >返回上一层</a-button>
    </div>
  </div>
  </a-spin>
  </div>
</template>

<script>


import moment from "moment";
import {getCaseSearch} from "@/service/CaseTemplate_api";
import headerConfig from '@/service/api_header_config'
export default {
  name: "Case",
  data(){
    return{
      spinning:false,
      casesh: '',
      // 病例搜索
      searchValueC:{
        name:'',
        mode:'',
        DayStart1:'',
        DayEnd1:'',
      },
      CaseList:[],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },

      columns : [
        {
          title: '医生信息',
          dataIndex: 'memberName',
          scopedSlots: { customRender: 'Doctor' },
        },
        {
          title: '患者信息',
          dataIndex: 'patientName',
          scopedSlots: { customRender: 'Patient' },
        },
        {
          title: '上传时间',
          dataIndex: 'created_time',
        },
        {
          title: '审核状态',
          scopedSlots: { customRender: 'audit_status' },
        },
        {
          title: '打印状态',
          scopedSlots: { customRender: 'isAudit' },
        },
        {
          title: '结算状态',
          scopedSlots: { customRender: 'isPrint' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },

      ],
    }
  },
   created() {
    this.$store.dispatch('setManageHeaderTitle',this.$route.query.title)
     this.casesh = this.$route.query.shcase
     this.adminLink = this.$route.query.link
     this.$store.commit('passBtn','')
  },
  mounted() {
    const data = {
      template_id : this.$route.query.id
    }
    this.getCaseList(data)
  },
  methods:{
    dateChange(date, dateString){
      this.searchValueC.DayStart1 = dateString[0]
      this.searchValueC.DayEnd1 = dateString[1]
    },
    // 获取病例列表
    async getCaseList(data){
      this.spinning = true
      const result = await getCaseSearch(data)
      if (result.code === 0){
        this.CaseList = result.data.rows
        this.pagination.total = result.data.count
      }else {
        this.$message.error("获取错误，请联系管理员~"+result.message);
      }
      this.spinning = false
    },
    //返回上一层
    back(){
      if (this.$route.query.key === '1'){
        this.$router.push({ path: `caseProgress`})
      }
      else {
        this.$router.push({ path: `caseProject`})
      }
    },
    //查看病例
    checkOk(row){
      this.$store.commit('passBtn',true)
      const userData = JSON.parse(window.localStorage.getItem("user_info"))
      this.$router.push({ path: `${this.adminLink}`,query:{id:row.id,user_id:userData.id}})
    },
    //批量导出
    excelDate(){
      const url = headerConfig.api_header + '/api/admin/case/caseReportExcelInfoByCase/'
          + this.$route.query.id + '?start_time=' + this.searchValueC.DayStart1 + '&end_time=' + this.searchValueC.DayEnd1+'&audit_status=' +this.searchValueC.mode;
      window.open(url)
    },
    //全部导出
    excelOk(){
      const url =  headerConfig.api_header + '/api/admin/case/caseReportExcelInfoByCase/' + this.$route.query.id ;
      window.open(url)
    },
    // 下载病例
     download(id){
      window.open( headerConfig.api_header+ '/api/admin/exportCaseTemplate/' +id)
     },
    // 分页
    async CasePagination(){
      const Page = this.pagination.current
      const caseForm = this.searchValueC
      const data = {
        template_id : this.$route.query.id,
        page_no : Page,
        audit_status : caseForm.mode,
        memberName : caseForm.name,
        start_time : caseForm.DayStart1,
        end_time : caseForm.DayEnd1
      }
      await this.getCaseList(data)

    },
    // 搜索确认
     searchOk(){
      const caseForm = this.searchValueC
      const data = {
        template_id : this.$route.query.id,
        audit_status : caseForm.mode,
        memberName : caseForm.name,
        start_time : caseForm.DayStart1,
        end_time : caseForm.DayEnd1
      }
      this.getCaseList(data)
    },

    // 搜索重置
    searchCancel(){
      this.searchValueC={}

      const data = {
        template_id : this.$route.query.id
      }
      this.getCaseList(data)
    },
    enterSearch(){
      this.searchOk()
    },
    //状态选择
    settlementChange(value){
      this.searchValueC.mode = value
      const data = {
        template_id : this.$route.query.id,
        audit_status : value
      }
      this.getCaseList(data)
    }

  },
}
</script>

<style lang="scss">
.CaseManagement{
  width: 100%;
  background-color: #f5f5f5;
  .CaseSearch{
    padding: 10px;
    height: 80px;
    width: 100%;
    margin: 10px auto;
    background-color: white;
    display: flex;
    .searchC{
      margin: 20px;
      display: inline-flex;
      align-items: center;
    }
    .searchButton{
      margin-left:20px;
    }
  }
  .CaseForm{
    width: 100%;
    margin: 10px auto;
    padding: 20px;
    background-color: white;
  }
}
</style>
